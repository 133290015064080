import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

// Type:
// Media sections generally contain video + text and gradient9 bg with colourful logo
// Primary sections have mixed content and usually text-heavy, have diff bgs
// Other types of sections can be added here as found
const Section = ({ children, type, bgColour, addedStyles, sectionId }) => {
  const sectionPadding = // approx average padding based on figma designs (not consistent)
    type === "media"
      ? tw`py-[30px] xl:(py-[50px])`
      : type === "primary"
      ? tw`py-[35px] xl:(py-[45px])`
      : null

  const sectionColour =
    bgColour === "gradient9" // narco
      ? tw`gradient9`
      : bgColour === "twilightBlue" // narco
      ? tw`bg-twilight-blue`
      : bgColour === "gradient43" // ih
      ? tw`gradient43`
      : bgColour === "gradient42"
      ? tw`gradient42`
      : bgColour === "alabaster" // shared
      ? tw`bg-alabaster-2`
      : bgColour === "seashell" // shared
      ? tw`bg-seashell`
      : bgColour === "hcpLightGrey" // shared
      ? tw`bg-hcpLightGrey`
      : bgColour === "transparent" // shared
      ? tw`bg-transparent`
      : type === "largeCallout" // shared
      ? tw`bg-white`
      : bgColour === "grey"
      ? tw`bg-merlin`
      : null

  return (
    <section
      css={[
        tw`relative`,
        sectionPadding,
        sectionColour,
        addedStyles && addedStyles,
      ]}
      id={sectionId}
    >
      {children}
    </section>
  )
}

export default Section

Section.propTypes = {
  type: PropTypes.string,
  bgColour: PropTypes.string,
  addedStyles: PropTypes.object,
  sectionId: PropTypes.string,
}

Section.defaultProps = {
  type: "primary",
  bgColour: "",
  sectionId: "",
  addedStyles: null,
}
